import { Injectable } from '@angular/core';
import { User } from '../models/auth.models';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from './config.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })

/**
 * Auth-service Component
 */
export class AuthenticationService {

    user!: User;
    currentUserValue: any;
    userData: any;
    privilegeRight: any;

    constructor(
        public http: HttpClient,
        public configService: ConfigService,
        public cookieService: CookieService
    ) { }

    login(data: any) {
        return this.http.post(this.configService.appConfig.api_url + '/vo/login', data);
    }

    logout(): void {
        this.cookieService.delete('adToken');
    }

    checkToken(token: string) {
        return this.http.get(this.configService.appConfig.api_url + '/vo/token/' + token);
    }

    get name() {
        return this.userData.name;
    }

    get image() {
        return this.userData.image;
    }

    get user_id() {
        return this.userData.id;
    }

    setUserdata(item: any) {
        this.userData = item;
    }

    setPrivilegeRight(item: any) {
        this.privilegeRight = item;
    }

    checkPrivilege(module: string, section: string) {
        let lowerModule = module.toLowerCase();
        let lowerSection = section.toLowerCase();
        if (this.privilegeRight[lowerModule]) {
            if (this.privilegeRight[lowerModule][lowerSection] && this.privilegeRight[lowerModule][lowerSection] == 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    auditLog(token: string,related_model:string,related_id:string){
        return this.http.get(this.configService.appConfig.api_url + '/vo/auditTrail/' + token +'/'+related_model+'/'+related_id);
    }
}

