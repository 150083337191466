import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CountUpModule } from 'ngx-countup';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgbDropdownModule, NgbAccordionModule, NgbNavModule, NgbProgressbarModule, NgbTooltipModule, NgbPopoverModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsModule } from '@angular/google-maps';

// Routings
import { SharedModule } from '../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.modules';

// Modules
import { UtilityModule } from './utility/utility.module';
import { AdvancedModule } from './advanced/advanced.module';
import { FormModule } from './form/form.module';
import { TablesModule } from './tables/tables.module';
import { IconsModule } from './icons/icons.module';
import { AppsModule } from './apps/apps.module';
import { WidgetModule } from '../shared/widget/widget.module';

// Components
import { DashboardsComponent } from './dashboard/dashboards/dashboards.component';
import { UiComponent } from './ui/ui.component';
import { TypographyComponent } from './typography/typography.component';
import { ChartsComponent } from './charts/charts.component';
import { MapsComponent } from './maps/maps.component';

@NgModule({
  declarations: [
    DashboardsComponent,
    UiComponent,
    TypographyComponent,
    ChartsComponent,
    MapsComponent,
  ],
  imports: [
    CommonModule,
    WidgetModule,
    PagesRoutingModule,
    ScrollToModule.forRoot(),
    NgApexchartsModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbProgressbarModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbPopoverModule,
    CountUpModule,
    UtilityModule,
    AdvancedModule,
    FormModule,
    TablesModule,
    IconsModule,
    AppsModule,
    SharedModule,
    GoogleMapsModule   
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class PagesModule { }
