<table class="table table-hover table-borderless align-middle table-centered table-nowrap mb-0">
    <thead class="table-light">
        <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Job title</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Status</th>
            <th colspan="2">Location</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let data of users">
            <td>
                {{data.id}}
            </td>
            <td>
                <img :src="{{data.image}}" alt="user" class="rounded-circle h-auto avatar-xs me-2">
                <span> {{data.name}}</span>
            </td>
            <td>
                {{data.title}}
            </td>
            <td>
                {{data.email}}
            </td>
            <td>
                {{data.phone}}
            </td>
            <td>
                <span class="badge bg-success-subtle text-success"
                    [ngClass]=" { 'text-danger bg-danger': data.status === 'Blocked' }">{{data.status}}</span>
            </td>
            <td>
                {{data.location}}
            </td>
            <td>
                <div class="dropdown" ngbDropdown>
                    <a class="text-muted dropdown-toggle font-size-16" role="button" href="javascript:void(0);"
                        ngbDropdownToggle data-bs-toggle="dropdown" aria-haspopup="true">
                        <i class="mdi mdi-dots-vertical"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);">Action</a>
                        <a class="dropdown-item" href="javascript:void(0);">Another action</a>
                        <a class="dropdown-item" href="javascript:void(0);">Something else here</a>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</table>