import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/core/services/config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

/**
 * Footer Component
 */
export class FooterComponent implements OnInit {

  versionNumber = "";

  constructor(
    private configService: ConfigService,
  ) {}
  /***
   * Current year
   */
  year: number = new Date().getFullYear();

  ngOnInit(): void {

    this.versionNumber = this.configService.appConfig.version;

  }
}
