import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

import { ChatUser, ChatMessage } from './chat.model';
import { chatData } from './data';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None
})

/**
 * Chat Component
 */
export class ChatComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  chatData!: ChatUser[];
  chatMessagesData!: ChatMessage[];
  usermessage!: string;
  formData!: UntypedFormGroup;
  chatSubmit?: boolean;
  
  @ViewChild('scrollRef') scrollRef:any;
  isreplyMessage = false;
  emoji = '';

  constructor(public formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Apps' },
      { label: 'Chat', active: true }
    ];

    // Validation
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });

    // Chat Data Get Function
    this._fetchData();
  }

  ngAfterViewInit() { }

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }

  /***
   * Chat Data Get
   */
  private _fetchData() {
    this.chatData = chatData;
  }

  /***
   * OnClick User Chat show
   */
   username:any = 'Doris Brown';
   profile: any = 'assets/images/users/avatar-2.jpg';
   message:any;
  chatUsername(event:any,id:any,name: any, profile: any) {

    document.querySelector('.chat-welcome-section')?.classList.add('d-none');
    document.querySelector('.user-chat')?.classList.remove('d-none');
    var removeClass = document.querySelectorAll('.chat-user-list li');
    removeClass.forEach((element: any) => {
        element.classList.remove('active');
    });
    event.target.closest('li').classList.add('active');

    var data = this.chatData.filter((chat:any) => {
      return chat.id === id;
    });
    
    this.username = data[0].name
    this.profile = data[0].image;
    this.message = data[0].messages;   
  }

  // Scroll Down
  onListScroll() {
    if (this.scrollRef !== undefined) {
      setTimeout(() => {
        this.scrollRef.SimpleBar.getScrollElement().scrollTop = this.scrollRef.SimpleBar.getScrollElement().scrollHeight;
      }, 500);
    }
  }

  /**
   * Save the message in chat
   */
  messageSave() {
    const message = this.formData.get('message')!.value;
    const currentDate = new Date();
    if (this.isreplyMessage == true) {
      var chatReplyUser = (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .conversation-name") as HTMLAreaElement).innerHTML;
      var chatReplyMessage = (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .mb-0")as HTMLAreaElement).innerText;
  
      this.message.push({
        align: 'right',
        profile: 'assets/images/users/avatar-4.jpg',
        replayName: chatReplyUser,
        replaymsg: chatReplyMessage,
        message,
        time: currentDate.getHours() + ':' + currentDate.getMinutes(),
      });
      this.onListScroll();
  
      // Set Form Data Reset
      this.formData = this.formBuilder.group({
        message: null,
      });
      this.isreplyMessage = false;
      document.querySelector('.replyCard')?.classList.remove('show');

     }
     else{
      if (this.formData.valid && message) {
        this.message.push({
          id: 1,
          align: 'right',
          profile: 'assets/images/users/avatar-4.jpg',
          message,
          time: currentDate.getHours() + ':' + currentDate.getMinutes(),
        });
        this.onListScroll();
        // Set Form Data Reset
        this.formData = this.formBuilder.group({
          message: null,
        });

      }
      this.emoji = '';
      this.chatSubmit = true;
     }
   
  }

  // Contact Search
  ContactSearch(){
    var input:any, filter:any, ul:any, li:any, a:any | undefined, i:any, txtValue:any;
    input = document.getElementById("searchContact") as HTMLAreaElement;
    filter = input.value.toUpperCase();      
    ul = document.querySelectorAll(".chat-user-list");
    ul.forEach((item:any)=>{
      li = item.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("h5")[0];
        txtValue = a?.innerText;
        if (txtValue?.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
            li[i].style.display = "none";
        }
      }
    })
  }

  // Message Search
  MessageSearch(){
    var input:any, filter:any, ul:any, li:any, a:any | undefined, i:any, txtValue:any;
    input = document.getElementById("searchMessage") as HTMLAreaElement;
    filter = input.value.toUpperCase();
    ul = document.getElementById("users-conversation");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("p")[0];
      txtValue = a?.innerText;
      if (txtValue?.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
    } else {
        li[i].style.display = "none";
    }
    }
  }

  // Copy Message
  copyMessage(event:any){
    navigator.clipboard.writeText(event.target.closest('.conversation-list').querySelector('.ctext-content').innerHTML);
    (document.getElementById("copyClipBoard") as HTMLElement).style.display = "block";
    setTimeout(() => {
    (document.getElementById("copyClipBoard") as HTMLElement).style.display = "none";
    }, 1000);
  }

  // Delete Message
  deleteMessage(event:any){
    event.target.closest('li').remove();
  }

  // Replay Message
  replyMessage(event:any,align:any){    
    this.isreplyMessage = true;
    document.querySelector('.replyCard')?.classList.add('show');
    var copyText = event.target.closest('.conversation-list').querySelector('.ctext-content').innerHTML;    
    (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .mb-0") as HTMLAreaElement).innerHTML = copyText;
    var msgOwnerName:any = event.target.closest("li").classList.contains("right") == true ? 'You' : document.querySelector('.user-profile-show')?.innerHTML;
    (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .conversation-name") as HTMLAreaElement).innerHTML = msgOwnerName;
  }

  // Close Replay Model
  closeReplay(){
    document.querySelector('.replyCard')?.classList.remove('show');
    this.isreplyMessage = false;
  }

  // Emoji Picker
  showEmojiPicker = false;
  sets:any = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set:any = 'twitter';
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event:any) {
    const { emoji } = this;
    const text = `${emoji}${event.emoji.native}`;
    this.emoji = text;
    this.showEmojiPicker = false;
  }

  onFocus() {
    this.showEmojiPicker = false;
  }
  onBlur() {
  }

  // Delete All Message
  deleteAllMessage(event:any){
    var allMsgDelete:any = document.getElementById('users-conversation')?.querySelectorAll('li');
    allMsgDelete.forEach((item:any)=>{
      item.remove();
    })
  }

}
