import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './template/layouts/layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { Page404Component } from './template/extrapages/page404/page404.component';
import { LoginComponent } from './login/login.component';
import { MylayoutComponent } from './mylayout/mylayout.component';

const routes: Routes = [
  { 
    path: 'login', 
    component: LoginComponent, 
    data: { title: 'Login | Vsmash' } 
  },
  { 
    path: '', component: MylayoutComponent, 
    loadChildren: () => import('./mypages/mypages.module').then(m => m.MypagesModule), canActivate: [AuthGuard], 
    data: { title: 'Management | Vsmash' } 
  },



  { path: 'account', loadChildren: () => import('./template/account/account.module').then(m => m.AccountModule) },
  { path: 'template', component: LayoutComponent, loadChildren: () => import('./template/pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  { path: 'pages', loadChildren: () => import('./template/extrapages/extrapages.module').then(m => m.ExtrapagesModule), canActivate: [AuthGuard] },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
