import { Injectable, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
@Injectable({ providedIn: 'root' })
export class AuthGuard {
    constructor(
        private router: Router,
        private AuthService: AuthenticationService,
        private cookieService: CookieService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let currentstate = state.url;
        if (currentstate == '/') {
            this.router.navigate(['/dashboard']);
            return false;
        } else {

            return new Promise((resolve, reject) => {
                var token = this.cookieService.get('adToken');
                if (token.length > 0) {
                    this.AuthService.checkToken(token).subscribe({
                        next: (data: any) => {
                            if (data.status == 200) {
                                this.AuthService.setUserdata(data.data.userdata)
                                this.AuthService.setPrivilegeRight(data.data.accessRight)
                                resolve(true);
                            } else {
                                this.router.navigate(['/login']);
                                resolve(false);
                            }
                        },
                        error: (error: any) => {
                            this.router.navigate(['/login']);
                            resolve(false);
                        }
                    });
                } else {
                    this.router.navigate(['/login']);
                    resolve(false);
                }
            });
        }
    }
}
