import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})

/**
 * Gallery Component
 */
export class GalleryComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;

  constructor() { }

  ngOnInit(): void {
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Pages' },
      { label: 'Gallery', active: true }
    ];

    this.filterredImages = this.list;
  }

  /**
   * Portfolio Classic Two Data
   */
   filterredImages: { img: string; title: string; auther: string; category:string; }[] | undefined;
   galleryFilter = 'all';
   list = [ {
      id:1,
      img:'assets/images/small/img-1.jpg',
      title:"Morning photoshoot",
      auther:"Scott Finch",
      category: 'project'
    },
    {
      id:2,
      img:'assets/images/small/img-2.jpg',
      title:"Coffee with Friends",
      auther:"Delores Williams",
      category: 'project'
    },
    {
      id:3,
      img:'assets/images/small/img-3.jpg',
      title:"Beautiful Day with Friends",
      auther:"Keith McCoy",
      category: 'project'
    },
    {
      id:4,
      img:'assets/images/small/img-5.jpg',
      title:"Lorem ipsum Dummy text",
      auther:"Silvia Martinez",
      category: 'project'
    },
    {
      id:5,
      img:'assets/images/small/img-1.jpg',
      title:"Morning photoshoot",
      auther:"Scott Finch",
      category: 'designing'
    },
    {
      id:6,
      img:'assets/images/small/img-3.jpg',
      title:"Beautiful Day with Friends",
      auther:"Keith McCoy",
      category: 'designing'
    },
    {
      id:7,
      img:'assets/images/small/img-5.jpg',
      title:"Lorem ipsum Dummy text",
      auther:"Silvia Martinez",
      category: 'designing'
    },
    {
      id:8,
      img:'assets/images/small/img-4.jpg',
      title:"Drawing a sketch",
      auther:"Clarence Smith",
      category: 'photography'
    },
    {
      id:8,
      img:'assets/images/small/img-6.jpg',
      title:"Project discussion with team",
      auther:"Patsy Waters",
      category: 'photography'
    },
    {
      id:9,
      img:'assets/images/small/img-7.jpg',
      title:"Natural atmosphere",
      auther:"Allyson Booker",
      category: 'photography'
    },
    {
      id:10,
      img:'assets/images/small/img-8.jpg',
      title:"Best Photographer",
      auther:"Monika Daker",
      category: 'photography'
    },
    {
      id:11,
      img:'assets/images/small/img-1.jpg',
      title:"Morning photoshoot",
      auther:"Scott Finch",
      category: 'development'
    },
    {
      id:12,
      img:'assets/images/small/img-2.jpg',
      title:"Coffee with Friends",
      auther:"Delores Williams",
      category: 'development'
    },
    
  ];

   /***
   * Active all category selected
   */
    activeCategory(category: string) {
      this.galleryFilter = category;
      if (this.galleryFilter === 'all') {
        this.filterredImages = this.list;
      } else {
        this.filterredImages = this.list.filter(x => x.category === this.galleryFilter);
      }
    }
  

}
