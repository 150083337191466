<!-- start page title -->
<app-pagetitle title="Invoice" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="invoice-title">
                    <h4 class="float-end font-size-16">Order # 12345</h4>
                    <div class="mb-4">
                        <img src="assets/images/logo-dark.png" alt="logo" height="24" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <address>
                            <strong class="mb-2 d-inline-block">Billed To:</strong><br>
                            John Smith
                            1234 Main<br>
                            Apt. 4B New Roads,<br>
                            Springfield, ST 54321
                        </address>
                    </div>
                    <div class="col-sm-6 text-sm-end">
                        <address class="mt-2 mt-sm-0">
                            <strong class="mb-2 d-inline-block">Shipped To:</strong><br>
                            Kenny Rigdon, 1234 Main<br>
                            Apt. 4B, Springfield,<br>
                            ST 54321
                        </address>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mt-3">
                        <address>
                            <strong class="mb-2 d-inline-block">Payment Method:</strong><br>
                            Visa ending **** 4242<br>
                            jsmith@email.com
                        </address>
                    </div>
                    <div class="col-6 mt-3 text-sm-end">
                        <address>
                            <strong class="mb-2 d-inline-block">Order Date:</strong><br>
                            January 16, 2021<br><br>
                        </address>
                    </div>
                </div>
                <div class="py-2 mt-3">
                    <h3 class="font-size-15 fw-semibold">Order summary</h3>
                </div>
                <div class="table-responsive">
                    <table class="table table-nowrap">
                        <thead class="table-light">
                            <tr>
                                <th style="width: 70px;">No.</th>
                                <th>Item</th>
                                <th class="text-end">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>01</td>
                                <td>Samply - Admin Dashboard Template</td>
                                <td class="text-end">$499.00</td>
                            </tr>

                            <tr>
                                <td>02</td>
                                <td>Samply - Landing Template</td>
                                <td class="text-end">$399.00</td>
                            </tr>

                            <tr>
                                <td>03</td>
                                <td>Skote - Admin Dashboard Template</td>
                                <td class="text-end">$499.00</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="text-end">
                                    <strong>Sub Total</strong>
                                </td>
                                <td class="text-end">$1397.00</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="border-0 text-end">
                                    <strong>Shipping</strong>
                                </td>
                                <td class="border-0 text-end">$13.00</td>
                            </tr>
                            <tr>
                                <td colspan="2" class="border-0 text-end">
                                    <strong>Total</strong>
                                </td>
                                <td class="border-0 text-end">
                                    <h4 class="m-0">$1410.00</h4>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-print-none">
                    <div class="float-end">
                        <a href="javascript:window.print()" class="btn btn-success waves-effect waves-light me-1"><i
                                class="fa fa-print"></i></a>
                        <a routerLink="/pages/invoice" class="btn btn-primary w-md waves-effect waves-light">Download</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>