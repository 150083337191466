import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { emailData } from './data';
import { Email } from './inbox.model';
import Swal from 'sweetalert2';
//import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss']
})

/**
 * Inbox Component
 */
export class InboxComponent implements OnInit {

  // public Editor = ClassicEditor;
  // bread crumb items
  breadCrumbItems!: Array<{}>;
  // paginated email data
  emailDatas:any;
  // emailData!: Email[];
  emailData!: Array<Email>;
  //emailData!: Array[];
  emailIds: number[] = [];
  // page number
  page = 1;
  // default page size
  pageSize = 5;
  // total number of records
  totalRecords = 0;
  // start and end index
  startIndex = 1;
  endIndex = 15;
  dataCount:any;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    /**
     * Fetches the data
     */
    this.fetchData();

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Email' },
      { label: 'Inbox', active: true }
    ];
  }

   /**
   * Fetches the data
   */
    private fetchData() {
      this.emailData = emailData;
      this.totalRecords = emailData.length;
      document.getElementById('emaildata')?.classList.add('d-none');
   }

  /**
   * Open modal
   * @param content content
   */
  open(content: any) {
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

  /***
   * send mail select multiple mail
   */
  selectMail(event: any, id: any) {
    if (event.target.checked) {
      this.emailIds.push(id);
    } else {
      this.emailIds.splice(this.emailIds.indexOf(id), 1);
    }
  }

  /***
   * Confirmation mail model
   */
  confirm() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#34c38f',
      cancelButtonColor: '#f46a6a',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        this.deleteMail();
        Swal.fire('Deleted!', 'Mail has been deleted.', 'success');
      }
    });
  }

  /***
   * Delete Mail
   */
  deleteMail() {
    const found = this.emailData.some(r => this.emailIds.indexOf(r.id) >= 0);
    if (found) {
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < this.emailIds.length; i++) {
        const obj: any = this.emailData.find(o => o.id === this.emailIds[i]);
        this.emailData.splice(this.emailData.indexOf(obj), 1);
      }
    }
    this.emailIds = [];
  }

  /**
   * Handle on page click event
   */
  onPageChange(page: any): void {
    this.startIndex = (page - 1) * this.pageSize + 1;
    this.endIndex = (page - 1) * this.pageSize + this.pageSize;
    if (this.endIndex > this.totalRecords) {
      this.endIndex = this.totalRecords;
    }
    this.emailData = emailData.slice(this.startIndex - 1, this.endIndex - 1);
  }

  /**
    * on settings button clicked from topbar
    */
   singleData:any = [];
   onSettingsButtonClicked(event:any,id:any) {
     this.singleData = this.emailData.filter( (order:any) => {
      return order.id === id;
    }); 
    this.singleData.forEach( (item:any) => {
      this.singleData = item;
    })
    document.body.classList.add('email-detail-show');
  }

}
