<!-- start page title -->
<app-pagetitle title="Gallery" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="card gallery">
    <div class="card-body">
        <div class="row">
            <div class="col-lg-12">
                <div class="justify-content-between d-flex align-items-center">
                    <h4 class="font-size-16 fw-semibold mb-0">Gallery Photos</h4>
                    <div class="dropdown">
                        <a class="dropdown-toggle fs-3 text-muted" href="#" role="button" data-bs-toggle="dropdown"
                            aria-haspopup="true">
                            <i class="mdi mdi-dots-vertical"></i>
                        </a>
                
                        <div class="dropdown-menu dropdown-menu-end">
                            <a class="dropdown-item" href="#">Edit</a>
                            <a class="dropdown-item" href="#">Action</a>
                            <a class="dropdown-item" href="#">Remove</a>
                        </div>
                    </div>
                    <!-- <a href="https://isotope.metafizzy.co/filtering.html" target="_blank" class="btn btn-sm badge-soft-secondary docs-btn">Docs <i class="mdi mdi-arrow-right align-middle"></i></a> -->
                </div><!-- end card header -->
                <hr class="text-muted">
            </div>
            <!-- end col -->
        </div> <!-- end row -->

        <div class="row">
            <div class="col-lg-12">
                <div class="text-center py-2">
                    <ul class="list-inline categories-filter gallery-btn hstack gap-3 justify-content-center flex-wrap" id="filter">
                        <li class="list-inline-item" [ngClass]="{'active': galleryFilter ==='all'}"><a class="categories fw-semibold" data-filter="*" (click)="activeCategory('all')">All</a></li>
                        <li class="list-inline-item" [ngClass]="{'active': galleryFilter ==='project'}"><a class="categories fw-semibold" data-filter=".project" (click)="activeCategory('project')">Project</a></li>
                        <li class="list-inline-item" [ngClass]="{'active': galleryFilter ==='designing'}"><a class="categories fw-semibold" data-filter=".designing" (click)="activeCategory('designing')">Designing</a></li>
                        <li class="list-inline-item" [ngClass]="{'active': galleryFilter ==='photography'}"><a class="categories fw-semibold" data-filter=".photography" (click)="activeCategory('photography')" >Photography</a></li>
                        <li class="list-inline-item" [ngClass]="{'active': galleryFilter ==='development'}"><a class="categories fw-semibold" data-filter=".development" (click)="activeCategory('development')" >Development</a></li>
                    </ul>
                </div>
                
            </div>
        </div> <!-- end row -->

        <div class="row gallery-wrapper">
            <div class="element-item col-xl-3 col-sm-6 project designing development" data-category="designing development" data-category="designing development" *ngFor="let data of filterredImages; let i=index">
                <div class="gallery-box card">
                    <div class="gallery-container">
                        <a class="image-popup" href="javascript:void(0);" title="">
                            <img class="gallery-img img-fluid mx-auto" src="{{data.img}}" alt=""/>
                        </a>
                    </div>

                    <div class="box-content p-3">
                        <h5 class="title font-size-17">{{data.title}}</h5>
                        <p class="font-size-13 mb-0">by <a href="" class="text-muted">Scott Finch</a></p>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
        
    </div> <!-- end card body -->
</div> <!-- end card -->