import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {Observable} from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {temModel} from './team.model';
import { teamData } from './data';
import { TeamService } from './team.service';
import { NgbdTeamSortableHeader, SortEvent } from './team-sortable.directive';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
  providers: [TeamService, DecimalPipe]
})

/**
 * Team Component
 */
export class TeamComponent implements OnInit {

  // bread crumb items
  breadCrumbItems!: Array<{}>;
  TeamDatas:any;

  // Table data
  TeamList!: Observable<temModel[]>;
  total: Observable<number>;
  @ViewChildren(NgbdTeamSortableHeader) headers!: QueryList<NgbdTeamSortableHeader>;

  constructor(private modalService: NgbModal,public service: TeamService) {
    this.TeamList = service.countries$;
    this.total = service.total$;
  }

  ngOnInit(): void {
    document.querySelector('.page-content')?.classList.add('team')
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Pages' },
      { label: 'Team', active: true }
    ];

    /**
     * fetches data
     */
     this.TeamList.subscribe(x => {
      this.TeamDatas =  Object.assign([], x);   
    });
  }

}
