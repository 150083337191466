<div class="account-pages">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="text-center mb-5">
                    <a routerLink="/" class="auth-logo">
                        <img src="assets/images/vsmash-logo-ob-300px.png" alt="" height="28" class="auth-logo-dark">
                        <img src="assets/images/vsmash-logo-ob-300px.png" alt="" height="28" class="auth-logo-light">
                    </a>
                    <p class="font-size-15 text-muted mt-3">VSMASH BACKEND {{versionCode}}</p>
                </div>
                <div class="card overflow-hidden">
                    <div class="row g-0">
                        <div class="col-lg-6">
                            <div class="p-lg-5 p-4">

                                <div>
                                    <h5>Welcome Back !</h5>
                                    <p class="text-muted">Sign in to continue to VSMASH.</p>
                                </div>

                                <div class="mt-4 pt-3">
                                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                                        <div class="mb-3">
                                            <label for="email" class="fw-semibold">Email</label>
                                            <input type="text" formControlName="email" class="form-control" id="email"
                                                placeholder="Enter email"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Email is required</div>
                                                <div *ngIf="f.email.errors.email">Email must be a valid email address
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mb-3 mb-4">
                                            <label for="userpassword" class="fw-semibold">Password</label>
                                            <input type="password" formControlName="password" class="form-control"
                                                id="userpassword" placeholder="Enter password"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Password is required</div>
                                            </div>
                                        </div>

                                        <div class="row align-items-center">
                                            <div class="col-6">
                                                <!-- <div class="form-check">
                                                    <input type="checkbox" class="form-check-input font-size-16" id="remember-check">
                                                    <label class="form-check-label" for="remember-check">Remember me</label>
                                                </div> -->
                                            </div>
                                            <div class="col-6">
                                                <div class="text-end">
                                                    <button class="btn btn-primary w-md waves-effect waves-light"
                                                        type="submit">Log In</button>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="mt-4">
                                            <a routerLink="/account/recoverpwd" class="text-muted"><i
                                                    class="mdi mdi-lock me-1"></i> Forgot your password?</a>
                                        </div> -->
                                    </form>
                                </div>

                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="p-lg-5 p-4 bg-auth h-100 d-none d-lg-block">
                                <div class="bg-overlay"></div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- end card -->
                <div class="mt-5 text-center">

                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end account page -->