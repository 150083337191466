// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: 'fake-backend', //firebase
  firebaseConfig: {
    apiKey: 'AIzaSyDX8IirSf54fDt5Nd2E79O-ulYzxGR1KuI',
    authDomain: 'vsmash-admin-9a220.firebaseapp.com',
    databaseURL: '',
    projectId: 'vsmash-admin-9a220',
    storageBucket: 'vsmash-admin-9a220.appspot.com',
    messagingSenderId: '442042816711',
    appId: '1:442042816711:web:5bbbb8371d0e5dfa8dc6e2',
    measurementId: ''
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
