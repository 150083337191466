import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 0,
        label: 'Dashboards',
        icon: 'bx bxs-dashboard',
        link: '/dashboard',

    },
    {
        id: 11,
        label: 'POS',
        section: 'add',
        module: 'cashier_pos',
        icon: 'bx bx-store-alt',
        link: '/cashierPos/add',

    },
    {
        id: 0,
        label: 'Sample',
        icon: 'bx bx-layer',
        section: 'list',
        module: 'template_sample',
        link: '/template/icons/boxicons',

    },
    {
        id: 1,
        label: 'Sales',
        isTitle: true,

    },
    //Sales Order
    {
        id: 2,
        label: 'Sales Order',
        icon: 'bx bx-cart',
        section: 'nav',
        module: 'sales_order',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'sales_order',
                link: '/salesOrder/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'sales_order',
                link: '/salesOrder/add',
                parentId: 2
            },
        ]
    },
    // Promocode
    {
        id: 2,
        label: 'Promocode',
        icon: 'bx bx-rename',
        section: 'nav',
        module: 'promocode',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'promocode',
                link: '/promocode/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'promocode',
                link: '/promocode/add',
                parentId: 2
            },
        ]
    },

    // Promotion
    {
        id: 2,
        label: 'Promotion',
        icon: 'fas fa-award',
        section: 'nav',
        module: 'promotion',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'promotion',
                link: '/promotion/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'promotion',
                link: '/promotion/add',
                parentId: 2
            },
        ]
    },
    {
        id: 1,
        label: 'Product',
        isTitle: true,

    },
    // Product
    {
        id: 2,
        label: 'Product',
        icon: 'bx bx-package',
        section: 'nav',
        module: 'product',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'product',
                link: '/product/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'product',
                link: '/product/add',
                parentId: 2
            },
            {
                id: 5,
                label: 'Batch Update Prices',
                section: 'batch_update_prices',
                module: 'product',
                link: '/product/batch_update_prices',
                parentId: 2
            },
        ]
    },

    // Product Attribute
    {
        id: 2,
        label: 'Product Attribute',
        icon: 'bx bxs-copy-alt',
        section: 'nav',
        module: 'product_attribute',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'product_attribute',
                link: '/productAttribute/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'product_attribute',
                link: '/productAttribute/add',
                parentId: 2
            },
        ]
    },

    // Product Tag
    {
        id: 2,
        label: 'Product Tag',
        icon: 'bx bx-purchase-tag-alt',
        section: 'nav',
        module: 'product_tag',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'product_tag',
                link: '/productTag/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'product_tag',
                link: '/productTag/add',
                parentId: 2
            },
        ]
    },

    // Product Binding
    {
        id: 2,
        label: 'Product Binding',
        icon: 'fas fa-hands-helping',
        section: 'nav',
        module: 'product_binding',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'product_binding',
                link: '/productBinding/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'product_binding',
                link: '/productBinding/add',
                parentId: 2
            },
        ]
    },

    // Product Addon
    {
        id: 2,
        label: 'Product Addon',
        icon: 'bx bx-duplicate',
        section: 'nav',
        module: 'product_addon',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'product_addon',
                link: '/productAddon/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'product_addon',
                link: '/productAddon/add',
                parentId: 2
            },
        ]
    },

    // Product Inventory
    {
        id: 2,
        label: 'Product Inventory',
        icon: 'bx bx-data',
        section: 'nav',
        module: 'product_inventory',
        subItems: [
            {
                id: 5,
                label: 'Balance',
                section: 'list',
                module: 'product_inventory',
                link: '/productInventory/log',
                parentId: 2
            },
            {
                id: 3,
                label: 'Log',
                section: 'list',
                module: 'product_inventory',
                link: '/productInventory/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'product_inventory',
                link: '/productInventory/add',
                parentId: 2
            },
            {
                id: 6,
                label: 'Batch Update',
                section: 'batch_update',
                module: 'product_inventory',
                link: '/productInventory/batch_update',
                parentId: 2
            },
        ]
    },

    //product category
    {
        id: 2,
        label: 'Product Category',
        icon: 'bx bx-grid-alt',
        section: 'nav',
        module: 'product_category',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'product_category',
                link: '/productCategory/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'product_category',
                link: '/productCategory/add',
                parentId: 2
            },
        ]
    },

    // Product Size Guide
    {
        id: 2,
        label: 'Product Size Guide',
        icon: 'bx bx-columns',
        section: 'nav',
        module: 'product_size_guide',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'product_size_guide',
                link: '/productSizeGuide/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'product_size_guide',
                link: '/productSizeGuide/add',
                parentId: 2
            },
        ]
    },

    {
        id: 1,
        label: 'General',
        isTitle: true,

    },

    //autocount multiple sync
    {
        id: 2,
        label: 'Autocount Multiple Sync',
        icon: 'bx bx-sync',
        section: 'nav',
        module: 'ac_multiple_sync',
        subItems: [
            {
                id: 3,
                label: 'List',
                parentModule: 'ac_multiple_sync',
                section: 'list',
                module: 'ac_multiple_sync',
                link: '/ac_multiple_sync/list',
                parentId: 2,

            },
        ]
    },

    //user
    {
        id: 2,
        label: 'User',
        icon: 'bx bx-user-circle',
        section: 'nav',
        module: 'user',
        subItems: [
            {
                id: 3,
                label: 'List',
                parentModule: 'user',
                section: 'list',
                module: 'user',
                link: '/user/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                parentModule: 'user',
                section: 'add',
                module: 'user',
                link: '/user/add',
                parentId: 2
            },
        ]
    },

    //user privilege
    {
        id: 2,
        label: 'User Privilege',
        icon: 'bx bx-check-shield',
        section: 'nav',
        module: 'user_privilege',
        subItems: [
            {
                id: 3,
                label: 'List',
                parentModule: 'user_privilege',
                section: 'list',
                module: 'user_privilege',
                link: '/userPrivilege/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                parentModule: 'user_privilege',
                section: 'add',
                module: 'user_privilege',
                link: '/userPrivilege/add',
                parentId: 2
            },
        ]
    },
    // Member
    {
        id: 2,
        label: 'Member',
        icon: 'bx bx-happy-alt',
        section: 'nav',
        module: 'member',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'member',
                link: '/member/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'member',
                link: '/member/add',
                parentId: 2
            },
        ]
    },
    // Member Wallet
    {
        id: 2,
        label: 'Member Wallet Topup',
        icon: 'bx bx-wallet',
        section: 'nav',
        module: 'member_wallet',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'member_wallet',
                link: '/member_wallet/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'member_wallet',
                link: '/member_wallet/add',
                parentId: 2
            },
        ]
    },
    {
        id: 2,
        label: 'Membership Tier Type',
        icon: 'bx bx-user-pin',
        section: 'nav',
        module: 'membership_tier_type',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'membership_tier_type',
                link: '/membership_tier_type/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'membership_tier_type',
                link: '/membership_tier_type/add',
                parentId: 2
            },
        ]
    },
    // Cart
    {
        id: 2,
        label: 'Cart',
        icon: 'bx bx-cart',
        section: 'nav',
        module: 'cart',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'cart',
                link: '/cart/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'cart',
                link: '/cart/add',
                parentId: 2
            },
        ]
    },
    {
        id: 2,
        label: 'Newsletter',
        icon: 'bx bx-envelope',
        section: 'nav',
        module: 'newsletter',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'newsletter',
                link: '/newsletter/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'newsletter',
                link: '/newsletter/add',
                parentId: 2
            },
        ]
    },
    {
        id: 2,
        label: 'Vpoint Rule',
        icon: 'bx bx-extension',
        section: 'nav',
        module: 'vpoint_rule',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'vpoint_rule',
                link: '/vpointRule/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'vpoint_rule',
                link: '/vpointRule/add',
                parentId: 2
            },
        ]
    },
    {
        id: 2,
        label: 'Vpoint Log',
        icon: 'bx bx-directions',
        section: 'nav',
        module: 'vpoint_log',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'vpoint_log',
                link: '/vpointLog/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'vpoint_log',
                link: '/vpointLog/add',
                parentId: 2
            },
        ]
    },
    {
        id: 2,
        label: 'Vpoint Event',
        icon: 'bx bx-calendar-event',
        section: 'nav',
        module: 'vpoint_event',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'vpoint_event',
                link: '/vpointEvent/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'vpoint_event',
                link: '/vpointEvent/add',
                parentId: 2
            },
        ]
    },
    {
        id: 2,
        label: 'Wallet Log',
        icon: 'bx bxs-wallet-alt',
        section: 'nav',
        module: 'wallet_log',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'wallet_log',
                link: '/walletLog/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'wallet_log',
                link: '/walletLog/add',
                parentId: 2
            },
        ]
    },

    {
        id: 1,
        label: 'Analytics',
        isTitle: true,
    },
    // Analytics
    {
        id: 27,
        label: 'Analytics',
        icon: 'bx bx-line-chart',
        section: 'nav',
        module: 'analytics',
        subItems: [
            {
                id: 271,
                label: 'Overview',
                section: 'overview',
                module: 'analytics',
                link: '/analytics/overview',
                parentId: 27,

            }, {
                id: 272,
                label: 'Products',
                section: 'products',
                module: 'analytics',
                link: '/analytics/products',
                parentId: 27,
            }, {
                id: 273,
                label: 'Revenue',
                section: 'revenue',
                module: 'analytics',
                link: '/analytics/revenue',
                parentId: 27,
            }, {
                id: 274,
                label: 'Orders',
                section: 'orders',
                module: 'analytics',
                link: '/analytics/orders',
                parentId: 27,
            }, {
                id: 275,
                label: 'Variations',
                section: 'variations',
                module: 'analytics',
                link: '/analytics/variations',
                parentId: 27,
            }, {
                id: 276,
                label: 'Categories',
                section: 'categories',
                module: 'analytics',
                link: '/analytics/categories',
                parentId: 27,
            }, {
                id: 277,
                label: 'Stock',
                section: 'stock',
                module: 'analytics',
                link: '/analytics/stock',
                parentId: 27,
            },
        ]
    },



    {
        id: 1,
        label: 'Report',
        isTitle: true,
    },

    // Report
    {
        id: 28,
        label: 'Report',
        icon: 'bx bx-line-chart',
        section: 'nav',
        module: 'report',
        subItems: [{
            id: 281,
            label: 'Sales',
            section: 'sales',
            module: 'report',
            link: '/report/sales',
            parentId: 28,
        }, {
            id: 282,
            label: 'Customer',
            section: 'customer',
            module: 'report',
            link: '/report/customer',
            parentId: 28,
        }, {
            id: 283,
            label: 'Stock',
            section: 'stock',
            module: 'report',
            link: '/report/stock',
            parentId: 28,
        }, {
            id: 284,
            label: 'Coupons',
            section: 'coupons',
            module: 'report',
            link: '/report/coupons',
            parentId: 28,
        },

        ]
    },


    {
        id: 1,
        label: 'Settings',
        isTitle: true,

    },

    // Web Navigation
    {
        id: 2,
        label: 'General Settings',
        icon: 'bx bx bx-cog',
        link: '/settings',

    },
    {
        id: 2,
        label: 'Image Gallery',
        icon: 'bx bx-images',
        link: '/image-gallery',

    },
    // Web Navigation
    {
        id: 2,
        label: 'Web Navigation',
        icon: 'bx bx bx-dock-top',
        link: '/webNavigation/list',

    },
    // {
    //     id: 2,
    //     label: 'Web Navigation',
    //     icon: 'bx bx-list-ul',
    //     section: 'nav',
    //     module: 'web_navigation',
    //     subItems: [
    //         {
    //             id: 3,
    //             label: 'List',
    //             section: 'list',
    //             module: 'web_navigation',
    //             link: '/webNavigation/list',
    //             parentId: 2,

    //         },
    //         {
    //             id: 4,
    //             label: 'Add',
    //             section: 'add',
    //             module: 'web_navigation',
    //             link: '/webNavigation/add',
    //             parentId: 2
    //         },
    //     ]
    // },

    // Footer
    {
        id: 2,
        label: 'Web Footer',
        icon: 'bx bx bx-dock-bottom',
        section: 'nav',
        module: 'web_footer',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'web_footer',
                link: '/webFooter/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'web_footer',
                link: '/webFooter/add',
                parentId: 2
            },
        ]
    },
    //banner
    {
        id: 2,
        label: 'Banner',
        icon: 'bx bx-images',
        section: 'nav',
        module: 'banner',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'banner',
                link: '/banner/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'banner',
                link: '/banner/add',
                parentId: 2
            },
        ]
    },
    //broadcast
    {
        id: 2,
        label: 'Broadcast',
        icon: 'bx bxs-megaphone',
        section: 'nav',
        module: 'broadcast',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'broadcast',
                link: '/broadcast/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'broadcast',
                link: '/broadcast/add',
                parentId: 2
            },
        ]
    },

    //currency
    {
        id: 2,
        label: 'Currency',
        icon: 'bx bx-dollar-circle',
        section: 'nav',
        module: 'currency',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'currency',
                link: '/currency/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'currency',
                link: '/currency/add',
                parentId: 2
            },
        ]
    },

    // Static Page
    {
        id: 2,
        label: 'Static Page',
        icon: 'bx bx-file',
        section: 'nav',
        module: 'static_page',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'static_page',
                link: '/staticPage/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'static_page',
                link: '/staticPage/add',
                parentId: 2
            },
        ]
    },


    // Payment Method
    {
        id: 2,
        label: 'Payment Method',
        icon: 'bx bx-credit-card',
        section: 'nav',
        module: 'payment_method',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'payment_method',
                link: '/paymentMethod/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'payment_method',
                link: '/paymentMethod/add',
                parentId: 2
            },
        ]
    },

    // Shipping Method
    {
        id: 2,
        label: 'Shipping Method',
        icon: 'fas fa-dolly-flatbed',
        section: 'nav',
        module: 'shipping_method',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'shipping_method',
                link: '/shippingMethod/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'shipping_method',
                link: '/shippingMethod/add',
                parentId: 2
            },
        ]
    },

    // Web Country
    {
        id: 2,
        label: 'Web Country',
        icon: 'bx bx-flag',
        section: 'nav',
        module: 'web_country',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'web_country',
                link: '/webCountry/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'web_country',
                link: '/webCountry/add',
                parentId: 2
            },
        ]
    },

    // Product Shipping Class
    {
        id: 2,
        label: 'Shipping Class',
        icon: 'bx bx-purchase-tag-alt',
        section: 'nav',
        module: 'product_shipping_class',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'product_shipping_class',
                link: '/productShippingClass/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'product_shipping_class',
                link: '/productShippingClass/add',
                parentId: 2
            },
        ]
    },

    // Shipping Zones
    {
        id: 2,
        label: 'Shipping Zones',
        icon: 'bx bx-broadcast',
        section: 'nav',
        module: 'shipping_zones',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'shipping_zones',
                link: '/shippingZones/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'shipping_zones',
                link: '/shippingZones/add',
                parentId: 2
            },
        ]
    },

    // Shipping Zone Location
    {
        id: 2,
        label: 'Shipping Zone Location',
        icon: 'bx bx-customize',
        section: 'nav',
        module: 'shipping_zone_location',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'shipping_zone_location',
                link: '/shippingZoneLocation/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'shipping_zone_location',
                link: '/shippingZoneLocation/add',
                parentId: 2
            },
        ]
    },

    // Sales Order Addon Settings
    {
        id: 2,
        label: 'SO Addon Settings',
        icon: 'bx bx-task',
        section: 'nav',
        module: 'sales_order_addon_settings',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'sales_order_addon_settings',
                link: '/salesOrderAddonSettings/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'sales_order_addon_settings',
                link: '/salesOrderAddonSettings/add',
                parentId: 2
            },
        ]
    },

    // Message Template
    {
        id: 2,
        label: 'MsgTemplate',
        icon: 'bx bx-message-alt-dots',
        section: 'nav',
        module: 'msg_template',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'msg_template',
                link: '/msgTemplate/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'msg_template',
                link: '/msgTemplate/add',
                parentId: 2
            },
        ]
    },


    // Reward Item
    {
        id: 2,
        label: 'RewardItems',
        icon: 'bx bx-gift',
        section: 'nav',
        module: 'reward_items',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'reward_items',
                link: '/rewardItems/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'reward_items',
                link: '/rewardItems/add',
                parentId: 2
            },
        ]
    },

    // Member Voucher
    {
        id: 2,
        label: 'MemberVoucher',
        icon: 'bx bx-collection',
        section: 'nav',
        module: 'member_voucher',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'member_voucher',
                link: '/memberVoucher/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'member_voucher',
                link: '/memberVoucher/add',
                parentId: 2
            },
        ]
    },
    // Cashier Pos
    {
        id: 2,
        label: 'CashierPos',
        icon: 'bx bx-store-alt',
        section: 'nav',
        module: 'cashier_pos',
        subItems: [
            {
                id: 3,
                label: 'List',
                section: 'list',
                module: 'cashier_pos',
                link: '/cashierPos/list',
                parentId: 2,

            },
            {
                id: 4,
                label: 'Add',
                section: 'add',
                module: 'cashier_pos',
                link: '/cashierPos/add',
                parentId: 2
            },
        ]
    },
];

