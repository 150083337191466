<div class="table-responsive mb-0">
  <table class="table align-middle table-striped table-nowrap mb-0">
    <tbody>
      <tr *ngFor="let data of orders">
        <td>{{ data.id }}</td>
        <td>
          <img src="{{ data.image }}" alt="user-image" style="height: 32px" class="rounded me-2" />
          {{ data.name }}
        </td>
        <td>
          <span class="badge rounded-pill bg-soft" [ngClass]="{
              'text-success bg-success': data.status === 'Delivered',
              'text-danger bg-danger': data.status === 'Cancel',
              'text-warning bg-warning': data.status === 'Shipped'
            }">{{ data.status }}</span>
        </td>
        <td>
          {{ data.total }}
        </td>
        <td>
          {{ data.date }}
        </td>
        <td>
          <button type="button" class="btn btn-light btn-sm waves-effect">
            <i class="mdi mdi-square-edit-outline me-1"></i> Edit
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- end table -->