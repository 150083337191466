<header id="page-topbar">
    <div class="navbar-header">
        <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box">
                <a routerLink="/dashboard" class="logo logo-dark">
                    <span class="logo-sm">
                        <img class="img-fluid" src="assets/images/vsmash-logo-73x70.png" alt="" height="22">
                    </span>
                    <span class="logo-lg align-items-center">
                        <img src="assets/images/Vsmash-Logo-300.png" alt=""  class="mt-3" height="55">
                    </span>
                </a>

                <a routerLink="/dashboard" class="logo logo-light">
                    <span class="logo-sm">
                        <img class="img-fluid" src="assets/images/vsmash-logo-73x70.png" alt="" height="22">
                    </span>
                    <span class="logo-lg align-items-center">
                        <img src="assets/images/Vsmash-Logo-300.png" alt="" class="mt-3" height="55">
                    </span>
                </a>
            </div>

            <button type="button" class="btn btn-sm px-3 font-size-16 vertinav-toggle header-item waves-effect"
                id="vertical-menu-btn" (click)="toggleMobileMenu($event)">
                <i class="fa fa-fw fa-bars"></i>
            </button>

            <button type="button"
                class="btn btn-sm px-3 font-size-16 horinav-toggle header-item waves-effect waves-light"
                data-bs-toggle="collapse" data-bs-target="#topnav-menu-content" (click)="toggleMobileMenu($event)">
                <i class="fa fa-fw fa-bars"></i>
            </button>

        </div>

        <div class="d-flex">

            <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
                <button type="button" class="btn header-item noti-icon waves-effect" ngbDropdownToggle
                    id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="mdi mdi-magnify"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
                    aria-labelledby="page-header-search-dropdown">

                    <form class="p-3">
                        <div class="form-group m-0">
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}"
                                    aria-label="Recipient's username">
                                <div class="input-group-append">
                                    <button class="btn btn-primary" type="submit"><i
                                            class="mdi mdi-magnify"></i></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="dropdown d-inline-block" ngbDropdown>
               
            </div>
            <div class="dropdown d-none d-lg-inline-block ms-1">
              
            </div>

            <!-- <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item noti-icon waves-effect" ngbDropdownToggle
                    id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <i class="mdi mdi-bell"></i>
                    <span class="badge bg-danger rounded-pill">3</span>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                    aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
                    <div class="p-3">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0"> Notifications </h6>
                            </div>
                            <div class="col-auto">
                                <a href="#" class="small" key="t-view-all"> View All</a>
                            </div>
                        </div>
                    </div>
                    <ngx-simplebar style="max-height: 230px;">
                        <a href="" class="text-reset notification-item d-block active">
                            <div class="d-flex">
                                <div class="avatar-xs me-3">
                                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                                        <i class="bx bx-cart"></i>
                                    </span>
                                </div>
                                <div class="flex-1">
                                    <h6 class="mt-0 mb-1" key="t-your-order">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' |
                                        translate}}</h6>
                                    <div class="font-size-13 text-muted">
                                        <p class="mb-1" key="t-grammer">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' |
                                            translate}}</p>
                                        <p class="mb-0 font-size-12"><i class="mdi mdi-clock-outline"></i> <span
                                                key="t-min-ago">{{ 'HEADER.NOTIFICATIONS.FIRST.TIME' |
                                                translate}}</span></p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="" class="text-reset notification-item d-block ">
                            <div class="d-flex">
                                <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs"
                                    alt="user-pic">
                                <div class="flex-1">
                                    <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>
                                    <div class="font-size-13 text-muted">
                                        <p class="mb-1" key="t-simplified">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' |
                                            translate}}</p>
                                        <p class="mb-0 font-size-12"><i class="mdi mdi-clock-outline"></i> <span
                                                key="t-hours-ago">{{ 'HEADER.NOTIFICATIONS.SECOND.TIME' |
                                                translate}}</span></p>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a href="" class="text-reset notification-item d-block ">
                            <div class="d-flex">
                                <div class="avatar-xs me-3">
                                    <span class="avatar-title bg-success rounded-circle font-size-16">
                                        <i class="bx bx-badge-check"></i>
                                    </span>
                                </div>
                                <div class="flex-1">
                                    <h6 class="mt-0 mb-1" key="t-shipped">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' |
                                        translate}}</h6>
                                    <div class="font-size-13 text-muted">
                                        <p class="mb-1" key="t-grammer">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' |
                                            translate}}</p>
                                        <p class="mb-0 font-size-12"><i class="mdi mdi-clock-outline"></i> <span
                                                key="t-min-ago">{{ 'HEADER.NOTIFICATIONS.THIRD.TIME' |
                                                translate}}</span></p>
                                    </div>
                                </div>
                            </div>
                        </a>

                        <a href="" class="text-reset notification-item d-block ">
                            <div class="d-flex">
                                <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-xs"
                                    alt="user-pic">
                                <div class="flex-1">
                                    <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                                    <div class="font-size-13 text-muted">
                                        <p class="mb-1" key="t-occidental">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' |
                                            translate}}</p>
                                        <p class="mb-0 font-size-12"><i class="mdi mdi-clock-outline"></i> <span
                                                key="t-hours-ago">{{ 'HEADER.NOTIFICATIONS.FOUR.TIME' |
                                                translate}}</span></p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ngx-simplebar>
                    <div class="p-2 border-top d-grid">
                        <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
                            <i class="mdi mdi-arrow-right-circle me-1"></i> <span>{{
                                'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}</span>
                        </a>
                    </div>
                </div>
            </div> -->

            <div class="dropdown d-inline-block" ngbDropdown>
                <button type="button" class="btn header-item waves-effect" ngbDropdownToggle
                    id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img class="rounded-circle header-profile-user" [src]="authService.image" alt="Header Avatar" *ngIf="authService.image">
                    <img class="rounded-circle header-profile-user" src="assets/images/users/user.png" alt="Header Avatar" *ngIf="!authService.image">
                    <span class="d-none d-xl-inline-block ms-1">{{authService.name}}</span>
                    <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <!-- item-->
                    <h6 class="dropdown-header">WELCOME</h6>
                    <a class="dropdown-item" routerLink="/user/edit/profile"><i
                            class="mdi mdi-account-circle text-muted font-size-16 align-middle me-1"></i> <span
                            class="align-middle" key="t-profile">Profile</span></a>
                    <a class="dropdown-item" routerLink="/pages/profile"><i
                            class="mdi mdi-message-text-outline text-muted font-size-16 align-middle me-1"></i> <span
                            class="align-middle" key="t-messages">Messages</span></a>
                 
                    <div class="dropdown-divider"></div>

                    <a class="dropdown-item" href="#"><i
                            class="mdi mdi-cog-outline text-muted font-size-16 align-middle me-1"></i> <span
                            class="align-middle" key="t-settings">Settings</span></a>
                   
                    <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i
                            class="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i> <span
                            class="align-middle" key="t-logout">logout </span></a>
                </div>
            </div>



        </div>
    </div>
</header>